import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LanguageDropdown from '../components/LanguageDropdown';
import Loading from '../components/Loading';
import TreeBox from '../components/TreeBox';
import pageStyles from '../styles/page-common.module.css';
import { getWeekIndex } from '../utils';
import { getTreeMetadata } from '../backend';


function Home() {
  const user = useSelector((state) => state.user);
  const streakState = useSelector((state) => state.streak);

  const [treeMetadata, setTreeMetadata] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const currentWeekIndex = getWeekIndex();

  // when page loads, download this week's trees
  useEffect(() => {
    async function loadTrees() {
      setTreeMetadata(null);
      try {
        // query for tree metadata
        setLoading(true);
        const ids = [
          `${user.currentLanguage}_${currentWeekIndex}_beg`,
          `${user.currentLanguage}_${currentWeekIndex}_int`,
          `${user.currentLanguage}_${currentWeekIndex}_adv`,
        ]
        const response = await getTreeMetadata({
          username: user.username,
          ids: ids,
        });

        // order trees by level
        const levels = ["beg", "int", "adv"];
        const orderedTrees = [];
        levels.forEach(level => orderedTrees.push(response.find(tree => tree._id.endsWith(`${level}`))));
        setTreeMetadata(orderedTrees);
        setError("");
      }
      catch(err) {
        if (err.response && err.response.status=== 404) {
          setError("This week's trees not found.")
        }
        else {
          setError("Some error occurred.")
        }
      }
      finally {
        setLoading(false);
      }
    }
    loadTrees();
  }, [currentWeekIndex, user]);

  // if page still loading return "loading" component
  if (loading) return <Loading/>;

  return (
    <div className={pageStyles.styledPage}>
      <h1>Welcome, {user.username}!</h1>
      {user.subscriptionStatus === 'past_due' && (
        <div className={pageStyles.alertBanner}>
          <p>Your subscription renewal payments have failed. Your account will remain active for 30 days since the first failed payment. Please update your payment method <NavLink to='/update-payment-method'>here</NavLink>.</p>
        </div>
      )}
      {user.subscriptionStatus === 'paused' && (
        <div className={pageStyles.alertBanner}>
          <p>Your subscription is paused.</p>
        </div>
      )}
      {user.subscriptionStatus === 'trialing' && user.subscriptionNextBilledAt && (
        <div className={pageStyles.alertBanner}>
          <p>You are on a free trial. Your trial ends on <strong>{user.subscriptionNextBilledAt.slice(0, 10)}</strong>.</p>
        </div>
      )}
      {user.subscriptionScheduledChange && user.subscriptionScheduledChange.action === 'cancel' && (
        <div className={pageStyles.alertBanner}>
          <p>Your subscription is due to cancel on <strong>{user.subscriptionScheduledChange.effective_at.slice(0, 10)}</strong>. Your account will be deleted.<br/><NavLink to='/unschedule-deletion'>Unschedule deletion</NavLink></p>
        </div>
      )}
      {streakState.pending && streakState.streak !== 0 && (
        <div className={pageStyles.alertBanner}>
          <p>You have not yet collected a tree this week. To maintain your <NavLink to='/streak'>weekly streak</NavLink>, complete a tree!</p>
        </div>
      )}
      <p>Language:</p>
      <LanguageDropdown className={pageStyles.dropdown}/>
      <hr className={pageStyles.hrule} />
      {error 
        ?
        <div className={pageStyles.alertBanner}><p>{error}</p></div>
        :
        <>
          <h2>This Week's Trees:</h2>
          <TreeBox treeMetadata={treeMetadata[0]} />
          <TreeBox treeMetadata={treeMetadata[1]} />
          <TreeBox treeMetadata={treeMetadata[2]} />
        </>
      }
      <hr className={pageStyles.hrule} />
      <p>Alternatively, visit your list of <NavLink to='/in-progress'>trees in progress</NavLink> or the <NavLink to='/archive'>tree archive</NavLink>.</p>
    </div>
  );
}

export default Home;