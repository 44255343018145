import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import treeBoxStyles from '../styles/component-treebox.module.css';
import {ReactComponent as IconTree0} from '../icons/icon_tree0.svg';
import {ReactComponent as IconTree1} from '../icons/icon_tree1.svg';
import {ReactComponent as IconTree2} from '../icons/icon_tree2.svg';
import {ReactComponent as IconTree3} from '../icons/icon_tree3.svg';
import {ReactComponent as IconTree4} from '../icons/icon_tree4.svg';
import {ReactComponent as IconTree5} from '../icons/icon_tree5.svg';
import {ReactComponent as IconTree6} from '../icons/icon_tree6.svg';
import {ReactComponent as IconTree7} from '../icons/icon_tree7.svg';
import { LANGUAGE_NAMES, LEVEL_NAMES } from "../constants";


function TreeBox({ treeMetadata }) {

  // get records data from redux state
  const treeRecords = useSelector((state) => state.treeRecords);
  const user = useSelector((state) => state.user);

  // compose treeRecordID
  const treeRecordID = treeMetadata['_id']
  
  // check if record exists
  const recordExists = treeRecords.hasOwnProperty(treeRecordID)

  let progressString;
  let icon;
  if(recordExists) {
    const treeRecord = treeRecords[treeRecordID]
    switch(treeRecord.leavesCollected) {
      case 1:
        icon = <IconTree1 className={treeBoxStyles.treeImage}/>;
        break;
      case 2:
        icon = <IconTree2 className={treeBoxStyles.treeImage}/>;
        break;
      case 3:
        icon = <IconTree3 className={treeBoxStyles.treeImage}/>;
        break;
      case 4:
        icon = <IconTree4 className={treeBoxStyles.treeImage}/>;
        break;
      case 5:
        icon = <IconTree5 className={treeBoxStyles.treeImage}/>;
        break;
      case 6:
        icon = <IconTree6 className={treeBoxStyles.treeImage}/>;
        break;
      case 7:
        icon = <IconTree7 className={treeBoxStyles.treeImage}/>;
        break;
      default:
        icon = <IconTree0 className={treeBoxStyles.treeImage}/>;
    }
    if(treeRecord.complete) {
      progressString = `Completed ${treeRecord.completionDate.slice(0, 10)}`
    } else {
      progressString = `Started ${treeRecord.startDate.slice(0, 10)}. ${treeRecord.leavesCollected}/7 leaves collected`
    }
  } else {
    icon = <IconTree0 className={treeBoxStyles.treeImage}/>;
    progressString = "Not started"
  }

  return (
    <NavLink 
      to={user.subscriptionStatus !== 'paused' ? '/tree' : undefined}
      state={{treeMetadata}}
      style={{display: 'contents', pointerEvents: user.subscriptionStatus === 'paused' ? 'none' : 'auto'}}
    >
      <div className={`${treeBoxStyles.treeBox} ${user.subscriptionStatus === 'paused' ? treeBoxStyles.disabled : ''}`}>
        <div className={treeBoxStyles.treeImageBox}>
          {icon}
        </div>
        <div className={treeBoxStyles.treeText}>
          <p><strong>{treeMetadata.title}</strong></p>
          <p>{treeMetadata.tagline}</p>
          <p><small>{LEVEL_NAMES[treeMetadata.level]} {LANGUAGE_NAMES[treeMetadata.language]} #{treeMetadata.week_index}. {progressString}</small></p>
          <p></p>
        </div>
      </div>
    </NavLink>
  );
}

export default TreeBox