import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import pageStyles from '../styles/page-common.module.css';
import { getTreeMetadata } from '../backend';
import Loading from '../components/Loading';
import TreeBox from '../components/TreeBox';


function InProgress() {

  // redux states
  const user = useSelector((state) => state.user);
  const {recordsLoaded, ...treeRecords} = useSelector((state) => state.treeRecords);

  // states
  const [loading, setLoading] = useState(true);
  const [treeRecordsLoading, setTreeRecordsLoading] = useState(true);
  const [treesLoading, setTreesLoading] = useState(true);
  const [error, setError] = useState("")
  const [inProgressTreeRecords, setInProgressTreeRecords] = useState([]);
  const [inProgressTrees, setInProgressTrees] = useState([]);

  // effect chain:
  // 1. get all records for in progress trees
  // 2. load corresponding trees
  // 3. set loading flag to false
  useEffect(() => {
    if(recordsLoaded && treeRecordsLoading) {
      // filter for trees in progress, sort by start date
      const recs = Object.entries(treeRecords)
      .filter(([key, treeRecord]) => !treeRecord.complete)
      .map(([key, treeRecord]) => {
        return { key, ...treeRecord };
      })
      recs.sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
      setInProgressTreeRecords(recs);
      setTreeRecordsLoading(false);
    }
  }, [treeRecords, recordsLoaded, treeRecordsLoading])
  useEffect(() => {
    async function fetchAllTrees() {
      try {
        if (inProgressTreeRecords.length === 0) {
          setInProgressTrees([]);
          setTreesLoading(false);
          return;
        }
        const trees = await getTreeMetadata({
          username: user.username,
          ids: inProgressTreeRecords.map(record => record.key),
        });

        // reorder the trees to match `inProgressTreeRecords`
        const treeMap = trees.reduce((map, tree) => {
          map[tree._id] = tree;
          return map;
        }, {});
        const orderedTrees = inProgressTreeRecords.map(record => {
          return treeMap[record.key];
        });

        setInProgressTrees(orderedTrees);
        setError("");
      } catch (error) {
        console.error("Error loading trees:", error);
        if (error.response && error.response.status === 404) {
          setError("Some trees weren't found.");
        } else {
          setError("An error occurred while fetching trees.");
        }
      } finally {
        setTreesLoading(false);
      }
    }
    if (!treeRecordsLoading && treesLoading) {
      fetchAllTrees();
    }
  }, [user, inProgressTreeRecords, treeRecordsLoading, treesLoading])
  useEffect(() => {
    if(!treesLoading && inProgressTrees.length === inProgressTreeRecords.length) {
      setLoading(false);
    }
  }, [treesLoading, inProgressTrees, inProgressTreeRecords])

  // if page still loading return "loading" component
  if (loading) return <Loading/>;

  // error page
  if (error) return <div className={pageStyles.styledPage}><div className={pageStyles.alertBanner}><p>{error}</p></div></div>

  return (
    <div className={pageStyles.styledPage}>
      <h1>Trees in Progress</h1>
      {inProgressTreeRecords.length > 0 ? (
        inProgressTreeRecords.map((treeRecord, index) => (
          <TreeBox key={index} treeMetadata={inProgressTrees[index]} />
        ))
      ) : (
        <p>No trees in progress.</p>
      )}
    </div>
  );
}

export default InProgress;