import { useState, useEffect, Fragment } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { stateStartLeaf, stateHintSentence, stateRevealQuestions, stateRevealResults, stateAnswerQuestion } from "../store";
import Loading from '../components/Loading';
import pageStyles from '../styles/page-common.module.css';
import leafStyles from '../styles/page-leaf.module.css';
import { INITIAL_HINT_COUNT, LANGUAGE_NAMES } from "../constants";


function ExplanationBlock({sentence, language, onHide}) {
  
  const translation = sentence.translations[language];
  const breakdown = sentence.breakdown[language];
  const notes = [...sentence.grammar_notes[language], ...sentence.idiom_notes[language]]
 
  console.log(sentence);
  return (
    <div className={leafStyles.explanationBlock}>
      <p><strong>Translation:</strong></p>
      <p>{translation}</p>
      <p><strong>Breakdown:</strong></p>
      <ul>
        {breakdown.map((item, index) => (
          <li key={index}>
            <strong>{item.text}</strong>: ({item.category}) {item.meaning}
          </li>
        ))}
      </ul>
      {
        notes.length !== 0 && (
          <>
            <p><strong>Notes</strong></p>
            <ul>
              {notes.map((item, index) => <li key={index}>{item}</li>)}
            </ul>
          </>
        )
      }
      {/* <strong>Breakdown:</strong> <p>{selectedSentence.breakdown[language]}</p>
      <strong>Grammar Notes:</strong> <p>{selectedSentence.grammar_notes[language]}</p>
      <strong>Idiom Notes:</strong> <p>{selectedSentence.idiom_notes[language]}</p> */}
      <p className={leafStyles.hideExplanationLine} onClick={onHide}>Hide Explanation</p>
    </div>
  )
} 

// paragraph component, shows text of paragraph and explanation block below if sentence within paragraph clicked
const Paragraph = ({ paragraph, selectedSentence, setSelectedSentence, treeRecordID, leafOrder, leafRecord, userLanguage }) => {

  return (
    <div>
      {/* text block */}
      <p>
        {paragraph.sentences.map((sentence, index) => (
          <Fragment key={index}>
            <Sentence
              sentence={sentence}
              isSelected={selectedSentence === sentence}
              setSelectedSentence={setSelectedSentence}
              treeRecordID={treeRecordID}
              leafOrder={leafOrder}
              paragraphOrder={paragraph.paragraph_order}
              leafRecord={leafRecord}
            />
            {index < paragraph.sentences.length - 1 && ' '}
          </Fragment>
        ))}
      </p>

      {/* explanation block */}
      {selectedSentence &&
        paragraph.sentences.includes(selectedSentence) && <ExplanationBlock sentence={selectedSentence} language={userLanguage} onHide={() => setSelectedSentence(null)}/>
      }
    </div>
  );
};


// sentence component, shows text of sentence
const Sentence = ({ sentence, isSelected, setSelectedSentence, treeRecordID, leafOrder, paragraphOrder, leafRecord }) => {

  // whether this sentence in list of previous hints
  const sentenceOrder = sentence.sentence_order;
  const prevHinted = leafRecord.hintedSentences.some(s=>s.paragraphOrder === paragraphOrder && s.sentenceOrder===sentenceOrder)

  let clickable = false;
  if (leafRecord.resultsRevealed) {
    clickable = true;
  } else if (leafRecord.questionsRevealed) {
    clickable = false;
  } else if (prevHinted || (INITIAL_HINT_COUNT - leafRecord.hintedSentences.length) > 0) {
    clickable = true;
  } 

  const onClick = () => {
    // if in pre-questions phase and clicking new sentence (not selected or prev hinted), add sentence to hint list
    if (!isSelected && !leafRecord.resultsRevealed && !prevHinted) {
      stateHintSentence(treeRecordID, leafOrder, paragraphOrder, sentenceOrder);
    }
    setSelectedSentence(isSelected ? null : sentence)
  }

  let className = leafStyles.sentence;
  if (isSelected) className += ` ${leafStyles.sentenceSelected}`;
  if (clickable) {
    className += ` ${leafStyles.sentenceClickable}`;
    if (prevHinted && !leafRecord.resultsRevealed) {
      className += ` ${leafStyles.sentencePrevClickable}`
    }
  }

  return (
    <span onClick={clickable ? onClick : null} className={className}>
      {sentence.text}
    </span>
  );
};

// question box component, shows checkbox questions, when submitted also shows answers
const Questions = ({ questions, userAnswers, treeRecordID, leafOrder, showResults, onSubmit, userLanguage }) => {
  
  // handler for form change
  const handleAnswerChange = (questionIndex, choiceIndex) => {
    stateAnswerQuestion(treeRecordID, leafOrder, questionIndex, choiceIndex);
  };

  // boolean: whether all questions answered
  const allAnswered = userAnswers.every((answer) => answer !== null);

  return (
    <div className={leafStyles.questionsContainer}>

      {/* questions */}
      {questions.map((question, index) => (
        <div key={index} className={leafStyles.question}>
          <p className={leafStyles.questionText}>{question.text[userLanguage]}</p>
          {question.options[userLanguage].map((option, choiceIndex) => {
            const isCorrectAnswer = showResults && choiceIndex === question.correct_answer;
            const isUserAnswer = showResults && userAnswers[index] === choiceIndex;
            let className = leafStyles.choiceLabel
            if (showResults && isCorrectAnswer) className += ` ${leafStyles.correctAnswer}`
            return (
              <label key={choiceIndex} className={className}>
                <input
                  type="radio"
                  name={`question-${index}`}
                  checked={userAnswers[index] === choiceIndex}
                  onChange={() => handleAnswerChange(index, choiceIndex)}
                  disabled={showResults}
                />
                {option}
                {showResults && isUserAnswer && <span className={leafStyles.answerFeedback}>{isCorrectAnswer ? "✔️" : "❌"}</span>}
              </label>
            );
          })}
        </div>
      ))}
      
      {/* submit answers button */}
      {!showResults && <button onClick={onSubmit} disabled={!allAnswered}>Check Answers</button>}

    </div>
  );
};


// main page component
function Leaf() {

  // get tree and leaf index from passed state props
  const location = useLocation();
  const { tree, leafOrder } = location.state

  // get leaf from tree
  const leaf = tree.leaves[leafOrder];

  // get tree data from redux state
  const treeRecords = useSelector((state) => state.treeRecords)

  // states
  const [leafRecord, setLeafRecord] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedSentence, setSelectedSentence] = useState(null);
  const [score, setScore] = useState(null);
  const [leafStateStarted, setLeafStateStarted] = useState(false);
  const [userLanguage, setUserLanguage] = useState('en');

  // on mount, effect chain:
  // start leaf in redux state -> get current leaf record -> finish loading
  useEffect(() => {
    if(tree._id && leaf && !leafStateStarted) {
      stateStartLeaf(tree._id, leafOrder, leaf.questions.length);
      setLeafStateStarted(true);
    }
  }, [leaf, tree._id, leafOrder, leafStateStarted])
  useEffect(() => {
    if (treeRecords[tree._id] && treeRecords[tree._id].leaves[leafOrder]) {
      setLeafRecord(treeRecords[tree._id].leaves[leafOrder]);
    }
  }, [treeRecords, tree._id, leafOrder])
  useEffect(() => {
    if(leafRecord) {
      setLoading(false);
    }
  }, [leafRecord])

  // when results are revealed (whether on mount or on submit), calculate results
  useEffect(() => {
    if (leafRecord && leafRecord.resultsRevealed) {
      const calculatedResults = leaf.questions.map(
        (question, index) => leafRecord.userAnswers[index] === question.correct_answer
      );
      setScore(calculatedResults.filter(Boolean).length);
    }
  }, [leafRecord, leaf])

  // handler for show questions button: deselect any sentences and set show state
  const handleShowQuestions = () => {
    setSelectedSentence(null);
    stateRevealQuestions(tree._id, leafOrder);
  };

  // handler for submit answers button
  const handleSubmit = () => {
    stateRevealResults(tree._id, leafOrder);
  };

  // if page still loading return "loading" component
  if (loading) return <Loading/>;

  return (
    <div className={pageStyles.styledPageLeftAligned}>
      
      {/* chapter title */}
      <h1>{tree.title}</h1>
      <div className={leafStyles.topSection}>
        {!leafRecord.questionsRevealed && <div className={pageStyles.alertBanner}> <p>Remaining hints: {INITIAL_HINT_COUNT - leafRecord.hintedSentences.length}</p></div>}
        <p>Translation Language:</p>
        <select
          name="translationLanguage"
          value={userLanguage}
          onChange={(e) => setUserLanguage(e.target.value)}
          className={pageStyles.dropdown}
        >
          {Object.values(tree.translation_languages).map((lang) => (
            <option key={lang} value={lang}>
              {LANGUAGE_NAMES[lang]}
            </option>
          ))}
        </select>
      </div>
      <hr className={pageStyles.hrule} />
      <h2>{leafOrder + 1}. {leaf.title}</h2>
      
      {/* paragraph components */}
      {leaf.paragraphs.map((paragraph, index) => (
        <Paragraph
          key={index}
          paragraph={paragraph}
          selectedSentence={selectedSentence}
          setSelectedSentence={setSelectedSentence}
          treeRecordID={tree._id}
          leafOrder={leafOrder}
          leafRecord={leafRecord}
          userLanguage={userLanguage}
        />
      ))}

      <hr className={pageStyles.hrule} />

      {/* 'show questions' button (disappears when pressed */}
      {!leafRecord.questionsRevealed && <button onClick={handleShowQuestions}>Show Questions</button>}

      {/* questions */}
      {leafRecord.questionsRevealed && (
        <Questions
          questions={leaf.questions}
          userAnswers={leafRecord.userAnswers}
          treeRecordID={tree._id}
          leafOrder={leafOrder}
          showResults={leafRecord.resultsRevealed}
          onSubmit={handleSubmit}
          userLanguage={userLanguage}
        />
      )}
      
      {/* total score */}
      {leafRecord.resultsRevealed && <span className={leafStyles.scoreLabel}>Total Score: {score} / {leaf.questions.length}</span>}
      
      {/* collect leaf button */}
      {!leafRecord.complete && leafRecord.resultsRevealed && <NavLink to='/leaf-complete' state={{ tree, leafOrder, score }} style={{display: 'contents'}}><button>Collect Leaf</button></NavLink>}
      {leafRecord.complete && <button disabled={true}>Leaf collected!</button>}

      {/* return to tree */}
      <NavLink to='/tree' state={{ 'treeMetadata': tree }} style={{display: 'contents'}}><button>Return to Tree</button></NavLink>

      <NavLink style={{display: 'contents'}} to='/contact'><span className={leafStyles.reportLink}>Report inaccurate or inappropriate content</span></NavLink>
    </div>
  );
}

export default Leaf;