import axios from "axios";
import { IS_DEV_FRONTEND } from "./constants";


function logError(error) {
  console.log(error);
  if(error.response) {
    console.log(error.response);
    console.log(error.response.status);
    console.log(error.response.headers);
  }
}


// get backend URL based on whether local env, or dev remote, or production remote
let baseURL;
if (process.env.NODE_ENV==='development') {
  baseURL = "http://localhost:5000";
} else if (IS_DEV_FRONTEND) {
  baseURL = "https://dev-server.lingoleaf.io"
} else {
  baseURL = "https://server.lingoleaf.io"
}

const api = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
      "Content-type": "application/json",
  },
});

async function clearTokens() {
  try {
    const response = await api.post('/logout');
    return response;
  }
  catch (error) {
    throw error;
  } 
}

async function fetchToken(username, password) {
  try {
    const response = await api.post('/login',  {username: username, password: password});
    return response;
  }
  catch (error) {
    throw error;
  } 
}

async function checkToken(username) {
  return api.post('/check',  {username: username}, {headers: {'X-CSRF-TOKEN': localStorage.getItem('csrf_token')}})
  .then(() => {
    return true;
  })
  .catch(error => {
    return false;
  })
}

async function confirmUser(token) {
  if (!token || typeof token !== 'string') {
    throw new Error('Invalid token provided');
  }

  try {
    const response = await api.post('/confirm', { token });
    return response;
  } catch (error) {
    console.error('Error confirming user:', error);
    throw error;
  }
}

async function resendConfirmation(email) {
    const response = await api.post('/resend-confirmation', { email });
    return response;
}

async function sendForgottenUsernameEmail(email) {
  const response = await api.post('/forgotten-username', { email });
  return response;
}


async function sendForgottenPasswordEmail(email, username) {
  const response = await api.post('/forgotten-password', { email, username });
  return response;
}


async function resetPassword(username, password, token) {
  const response = await api.post('/reset-password', { username, password, token });
  return response;
}

async function checkRegDetails(userData) {
  const response = await api.post('/validate-registration-data', userData)
  return response;
}

async function createUser(userData) {
  const response = await api.post('/users', userData)
  return response;
}

async function deleteUser(username) {
  const response = await api.delete(
    `/users/${username}`,
    {headers: {'X-CSRF-TOKEN': localStorage.getItem('csrf_token')}}
  )
  return response;
}

async function scheduleUserDeletion(username) {
  const response = await api.post(
    `/users/${username}/schedule-deletion`,
    {},
    {headers: {'X-CSRF-TOKEN': localStorage.getItem('csrf_token')}}
  )
  return response;
}

async function unscheduleUserDeletion(username) {
  const response = await api.post(
    `/users/${username}/unschedule-deletion`, 
    {},
    {headers: {'X-CSRF-TOKEN': localStorage.getItem('csrf_token')}},
  )
  return response;
}

async function getUserData(username) {
  try {
    const response = await api.get(
      `/users/${username}`,
      {headers: {'X-CSRF-TOKEN': localStorage.getItem('csrf_token')}}
    )
    return response.data.user_data
  } catch (error) {
    logError(error);
    return;
  }
}

async function updateUserData(username, userData) {
  try {
    await api.patch(
      `/users/${username}`,
      userData,
      {headers: {'X-CSRF-TOKEN': localStorage.getItem('csrf_token')}}
    )
    return
  } catch (error) {
    logError(error);
    return;
  }
}


async function getTreeMetadata({
  username,
  language = null,
  level=null,
  year = null,
  translation = null,
  ids=null,
}) {
  // can't have id list alongside other params
  if (ids) {
    if (language || level || year || translation) {
      throw new Error("language, level, year, translation cannot be used with id list.");
    }
  }

  // build query params
  const params = { username };
  if (ids) {
    params["tree_ids[]"] = ids;
  } else {
    if (language) params.language = language;
    if (level) params.level = level;
    if (year) params.year = year;
    if (translation) params.translation = translation;
  }

  // make request
  try {
    const response = await api.get("/tree-metadata", {
      headers: { "X-CSRF-TOKEN": localStorage.getItem("csrf_token") },
      params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

async function getTree({username, tree_id}) {
  try {
    const params = { username };
    const response = await api.get(`/trees/${tree_id}`, {
      headers: { "X-CSRF-TOKEN": localStorage.getItem("csrf_token") },
      params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

async function getConstant({name}) {
  const response = await api.get(`/constants/${name}`);
  return response.data;
}


async function getPaddleUpdateTransactionID(username) {
  try {
    const response = await api.get(
      `/update-payment-transaction/${username}`,
      {headers: {'X-CSRF-TOKEN': localStorage.getItem('csrf_token')}}
    )
    return response.data.txn_id
  } catch (error) {
    logError(error);
    return;
  }
}

export { fetchToken, clearTokens, checkToken, confirmUser, resendConfirmation, sendForgottenUsernameEmail, sendForgottenPasswordEmail, resetPassword, checkRegDetails, getUserData, updateUserData, getTreeMetadata, getTree, createUser, deleteUser, scheduleUserDeletion, unscheduleUserDeletion, getConstant, getPaddleUpdateTransactionID }
