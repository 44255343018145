import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getTreeMetadata, getConstant } from '../backend';
import pageStyles from '../styles/page-common.module.css';
import LanguageDropdown from '../components/LanguageDropdown';
import Loading from '../components/Loading';
import TreeBox from '../components/TreeBox';
import { LEVEL_NAMES, LANGUAGE_NAMES } from '../constants';


function Archive() {
 
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState("");
  const [error, setError] = useState(null);
  const [trees, setTrees] = useState([]);

  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedLevel, setSelectedLevel] = useState('beg');
  const [transLanguages, setTransLanguages] = useState([]);
  const [selectedTranslationLanguage, setSelectedTranslationLanguage] = useState("");

  // generate array of years from 2024 to the current year
  const years = [];
  for (let year = 2024; year <= currentYear; year++) {
    years.push(year);
  }

  // when page loads, download all trees according to query
  useEffect(() => {
    async function loadTrees() {
      setTrees([]);
      try {
        setLoading(true);
        const response = await getTreeMetadata({
          username: user.username,
          language: user.currentLanguage,
          level: selectedLevel,
          year: selectedYear,
          translation: selectedTranslationLanguage || undefined
        });
        const sortedTrees = response.sort((a, b) => a.week_index - b.week_index);
        setTrees(sortedTrees);
        const response2 = await getConstant({name: 'translation_languages'});
        setTransLanguages(response2);
        setError("");
      }
      catch(error) {
        if (error.response && error.response.status=== 404) {
          setError("Trees not found or not available for the selected criteria.")
        }
        else {
          setError("Some error occurred.")
        }
      }
      finally {
        setLoading(false);
      }
    }
    loadTrees();
  }, [user, selectedYear, selectedLevel, selectedTranslationLanguage]);

  // if page still loading return "loading" component
  if (loading) return <Loading/>;

  return (
    <div className={pageStyles.styledPage}>
      <h1>Tree Archive</h1>
      <p>Language:</p>
      <LanguageDropdown className={pageStyles.dropdown}/>
      <p>Level:</p>
      <select
        name="readingLevel"
        value={selectedLevel}
        onChange={(e) => setSelectedLevel(e.target.value)}
        className={pageStyles.dropdown}
      >
        {Object.entries(LEVEL_NAMES).map(([code, name]) => (
          <option key={code} value={code}>
            {name}
          </option>
        ))}
      </select>
      <p>Year:</p>
      <select
        name="treeYear"
        value={selectedYear}
        onChange={(e) => setSelectedYear(parseInt(e.target.value, 10))}
        className={pageStyles.dropdown}
      >
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
      <p>Translation Language (OPTIONAL):</p>
      <select
        name="translationLanguage"
        value={selectedTranslationLanguage}
        onChange={(e) => setSelectedTranslationLanguage(e.target.value)}
        className={pageStyles.dropdown}
      >
        <option value=""></option> {/* Default option */}
        {Object.values(transLanguages).map((lang) => (
          <option key={lang} value={lang}>
            {LANGUAGE_NAMES[lang]}
          </option>
        ))}
      </select>
      <p><small>"Translation Language" is the language of translations, annotations, and questions. If not specified then all trees are returned regardless of which translation languages are available.</small></p>
      <hr className={pageStyles.hrule} />
      <h2>Trees</h2>
      {error ? (
        <div className={pageStyles.alertBanner}> <p>{error}</p></div>
      ) : (
        trees.map((tree, index) => (
          <TreeBox key={index} treeMetadata={tree}/>
        ))
      )}
    </div>
  );
}

export default Archive;