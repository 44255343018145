import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { stateCompleteLeaf } from '../store';
import {ReactComponent as IconTree0} from '../icons/icon_tree0.svg';
import {ReactComponent as IconTree1} from '../icons/icon_tree1.svg';
import {ReactComponent as IconTree2} from '../icons/icon_tree2.svg';
import {ReactComponent as IconTree3} from '../icons/icon_tree3.svg';
import {ReactComponent as IconTree4} from '../icons/icon_tree4.svg';
import {ReactComponent as IconTree5} from '../icons/icon_tree5.svg';
import {ReactComponent as IconTree6} from '../icons/icon_tree6.svg';
import {ReactComponent as IconTree7} from '../icons/icon_tree7.svg';
import {ReactComponent as IconArrow} from '../icons/icon_rightarrow.svg';
import pageStyles from '../styles/page-common.module.css';
import leafCompleteStyles from '../styles/page-leafcomplete.module.css'


function LeafComplete() {

  // get tree and leaf index from passed state props
  const location = useLocation();
  const { tree, leafOrder, score } = location.state

  // get tree record
  const treeRecord = useSelector((state) => state.treeRecords)[tree._id]

  // find out if tree complete
  const treeComplete = treeRecord.complete

  // two tree icons
  let icon1
  let icon2
  switch(treeRecord.leavesCollected) {
      case 1:
        icon1 = <IconTree0 className={leafCompleteStyles.treeImage}/>;
        icon2 = <IconTree1 className={leafCompleteStyles.treeImage}/>;
        break;
      case 2:
        icon1 = <IconTree1 className={leafCompleteStyles.treeImage}/>;
        icon2 = <IconTree2 className={leafCompleteStyles.treeImage}/>;
        break;
      case 3:
        icon1 = <IconTree2 className={leafCompleteStyles.treeImage}/>;
        icon2 = <IconTree3 className={leafCompleteStyles.treeImage}/>;
        break;
      case 4:
        icon1 = <IconTree3 className={leafCompleteStyles.treeImage}/>;
        icon2 = <IconTree4 className={leafCompleteStyles.treeImage}/>;
        break;
      case 5:
        icon1 = <IconTree4 className={leafCompleteStyles.treeImage}/>;
        icon2 = <IconTree5 className={leafCompleteStyles.treeImage}/>;
        break;
      case 6:
        icon1 = <IconTree5 className={leafCompleteStyles.treeImage}/>;
        icon2 = <IconTree6 className={leafCompleteStyles.treeImage}/>;
        break;
      case 7:
        icon1 = <IconTree6 className={leafCompleteStyles.treeImage}/>;
        icon2 = <IconTree7 className={leafCompleteStyles.treeImage}/>;
        break;
      default:
        icon1 = <IconTree0 className={leafCompleteStyles.treeImage}/>;
        icon2 = <IconTree1 className={leafCompleteStyles.treeImage}/>;
    }

  // on mount, mark leaf as complete
  useEffect(() => {
    if (tree._id) {
      stateCompleteLeaf(tree._id, leafOrder, score);
    }
  }, [leafOrder, score, tree._id])

  return (
    <div className={pageStyles.styledPage}>
      {treeComplete ? <h1>Congratulations, you have completed the tree!</h1> : <h1>Congratulations, you have collected a leaf!</h1>}
      <div className={leafCompleteStyles.treeImagesBox}>
        {icon1}
        <IconArrow className={leafCompleteStyles.arrow}/>
        {icon2}
      </div>
      <NavLink to='/leaf' state={{ tree, leafOrder }} style={{display: 'contents'}}><button>Review Leaf</button></NavLink>
      <NavLink to='/tree' state={{ 'treeMetadata': tree }} style={{display: 'contents'}}><button>Return to Tree</button></NavLink>
      <NavLink to='/home' style={{display: 'contents'}}><button>Return Home</button></NavLink>
    </div>
  );
}

export default LeafComplete;