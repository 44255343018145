import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import pageStyles from '../styles/page-common.module.css';
import { getTreeMetadata } from '../backend';
import TreeBox from '../components/TreeBox';
import Loading from '../components/Loading';


function CompletedTrees() {

  // redux states
  const user = useSelector((state) => state.user);
  const {recordsLoaded, ...treeRecords} = useSelector((state) => state.treeRecords);

  // states
  const [loading, setLoading] = useState(true);
  const [treeRecordsLoading, setTreeRecordsLoading] = useState(true);
  const [treesLoading, setTreesLoading] = useState(true);
  const [error, setError] = useState("")
  const [completedTreeRecords, setCompletedTreeRecords] = useState([]);
  const [completedTrees, setCompletedTrees] = useState([]);

  // effect chain:
  // 1. get all records for completed trees
  // 2. load corresponding trees
  // 3. set loading flag to false
  useEffect(() => {
    if(recordsLoaded && treeRecordsLoading) {
      // filter for completed trees sort by start date
      const recs = Object.entries(treeRecords)
      .filter(([key, treeRecord]) => treeRecord.complete) // Filter based on the 'complete' property
      .map(([key, treeRecord]) => {
        return { key, ...treeRecord };
      })
      recs.sort((a, b) => new Date(b.completionDate) - new Date(a.completionDate))
      setCompletedTreeRecords(recs);
      setTreeRecordsLoading(false);
    }
  }, [treeRecords, recordsLoaded, treeRecordsLoading])
  useEffect(() => {
    async function fetchAllTrees() {
      try {
        if (completedTreeRecords.length === 0) {
          setCompletedTrees([]);
          setTreesLoading(false);
          return;
        }
        // query trees
        const trees = await getTreeMetadata({
          username: user.username,
          ids: completedTreeRecords.map(record => record.key),
        });
        // reorder the trees to match `completedTreeRecords`
        const treeMap = trees.reduce((map, tree) => {
          map[tree._id] = tree;
          return map;
        }, {});
        const orderedTrees = completedTreeRecords.map(record => {
          return treeMap[record.key];
        });
        setCompletedTrees(orderedTrees);
        setError("");
      } catch (error) {
        console.error("Error loading trees:", error);
        if (error.response && error.response.status === 404) {
          setError("Some trees weren't found.");
        } else {
          setError("An error occurred while fetching trees.");
        }
      } finally {
        setTreesLoading(false);
      }
    }
    if (!treeRecordsLoading && treesLoading) {
      fetchAllTrees();
    }
  }, [user, completedTreeRecords, treeRecordsLoading, treesLoading])
  useEffect(() => {
    if(!treesLoading && completedTrees.length === completedTreeRecords.length) {
      setLoading(false);
    }
  }, [treesLoading, completedTrees, completedTreeRecords])

  // if page still loading return "loading" component
  if (loading) return <Loading/>;

  // error page
  if (error) return <div className={pageStyles.styledPage}><div className={pageStyles.alertBanner}><p>{error}</p></div></div>

  return (
    <div className={pageStyles.styledPage}>
      <h1>Completed Trees</h1>
      {completedTreeRecords.length > 0 ? (
        completedTreeRecords.map((treeRecord, index) => (
          <TreeBox key={index} treeMetadata={completedTrees[index]}/>
        ))
      ) : (
        <p>No completed trees.</p>
      )}
    </div>
  );
}

export default CompletedTrees;