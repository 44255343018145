import { useEffect, useState } from 'react';
import { getConstant } from '../backend';
import { LANGUAGE_NAMES } from '../constants';
import pageStyles from '../styles/page-common.module.css';


function Languages() {

  const [readingLanguages, setReadingLanguages] = useState([]);
  const [transLanguages, setTransLanguages] = useState([]);

  // when page loads, download lists of supported languages from DB
  useEffect(() => {
    async function loadLanguages() {
      const response1 = await getConstant({name: 'reading_languages'});
      setReadingLanguages(response1);
      const response2 = await getConstant({name: 'translation_languages'});
      setTransLanguages(response2);
    }
    loadLanguages();
  }, []);

  return (
    <div className={pageStyles.styledPageLeftAligned}>
      <h1>Languages</h1>
      <p>Below is a list of our supported 'reading languages' (the languages used for the reading material, i.e., the language you want to learn) and a list of our supported 'translation languages' (the languages used for the translations and explanations of the reading material, i.e., a language you are already proficient in).</p>
      <p> For example, if you are a native French speaker wanting to learn Japanese, you would choose French as your translation language and Japanese as your reading language.</p> 
      <p>We're planning to add more languages in future, so watch this space!</p>
      <h2>Reading languages</h2>
      <ul>
        {Object.values(readingLanguages).map((value, index)=><li key={index}><strong>{LANGUAGE_NAMES[value]}</strong></li>)}
      </ul>
      <h2>Translation languages</h2>
      <ul>
        {Object.values(transLanguages).map((value, index)=><li key={index}><strong>{LANGUAGE_NAMES[value]}</strong></li>)}
      </ul>
    </div>
  );
}

export default Languages;